import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { IProduct, EPrivileges, hasAnyPrivileges } from '../../apitypes/index';
import Dialog from '../../comps/dialog/Dialog';
import { Buffer } from 'buffer';
import ImportCSV from '../../comps/importCSV/ImportCSV';
import EditProductForm from './EditProductForm';

import { BsPlus } from 'react-icons/bs';
import { VscSearch, VscRefresh, VscEdit } from 'react-icons/vsc';
import { RxUpload, RxDownload } from 'react-icons/rx';
import { AiOutlineDelete } from 'react-icons/ai';


interface IState {
  search: string,
  products: IProduct[],
  editProduct: IProduct | null,
  createNew: boolean,
  showDeleteDialog: boolean,
  selectedIDs: string[],
  sortBy: string,
  sortOrder: E_SORTORDER,
  showImport: boolean,
  importLines: null | string[][]
}

class ProductManagement extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      search: '',
      products: [],
      editProduct: null,
      createNew: false,
      showDeleteDialog: false,
      selectedIDs: [],
      sortBy: '',
      sortOrder: E_SORTORDER.ASC,
      showImport: false,
      importLines: null
    }
  }

  async componentDidMount(): Promise<void> {
    this.reloadObjects();
  }

  sortObjectsBy(sortBy: string) {
    const { products, sortOrder } = this.state;
    let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
    if (sortBy === this.state.sortBy) {
      //Change sort order
      if (sortOrder === E_SORTORDER.ASC) {
        newSortOrder = E_SORTORDER.DESC;
      }
    }
    console.log(`Sort ${sortBy} ${newSortOrder}`);

    this.setState({
      sortOrder: newSortOrder,
      sortBy: sortBy,
      products: sortObjects(products, sortBy, newSortOrder)
    });
  }


  async reloadObjects() {

    showLoading(true);
    this.setState({
      products: await api.product.getProducts(),
      editProduct: null,
      createNew: false,
      selectedIDs: [],
      showDeleteDialog: false,
    });
    showLoading(false);
  }



  exportObjects() {
    const { products } = this.state;
    let csv = "ref\tname\tnoteauto\tenabled\r\n";
    for (var pro of products) {
      csv += `${pro.ref}\t${pro.name}\t${pro.noteAuto}\t${pro.enabled ? '1' : '0'}\r\n`;
    }
    this.downloadDataToUser(csv, "products_export.csv");
  }

  async importObjects() {
    const { importLines, products } = this.state;

    if (importLines === null || importLines.length === 0) {
      showError(i18n.s('importIsNull'));
      return;
    }

    //check column position
    const refIndex = importLines[0].findIndex(c => c.trim().toLowerCase() === "ref");
    const nameIndex = importLines[0].findIndex(c => c.trim().toLowerCase() === "name");
    const noteautoIndex = importLines[0].findIndex(c => c.trim().toLowerCase() === "noteauto");
    const enabledIndex = importLines[0].findIndex(c => c.trim().toLowerCase() === "enabled");
    if (refIndex === -1
      || nameIndex === -1
      || noteautoIndex === -1
      || enabledIndex === -1
    ) {
      showError(i18n.s('missingColumn'));
      return null;
    }

    for (let i = 1; i < importLines.length; i++) {
      try {
        const line = importLines[i];
        const ref = line[refIndex];
        const name = line[nameIndex];
        const noteAuto = line[noteautoIndex];
        const enabled = line[enabledIndex].toLowerCase().trim() === "true" || parseInt(line[enabledIndex]) > 0;

        const product = products.find(p => p.ref === ref);
        if (product) {
          await api.product.updateProduct(product.ref, { ...product, name, noteAuto, enabled });
          console.log(`Import: updated product ${product.ref}`)
        }
        else {
          await api.product.createProduct({ ref, name, noteAuto, enabled })
          console.log(`Import: created product ${ref}`)
        }
      }
      catch (err: any) {
        showError(err.message);
      }

    }

    this.setState({
      showDeleteDialog: false,
      selectedIDs: [],
      showImport: false,
      importLines: null
    });
    this.reloadObjects();
  }

  downloadDataToUser(data: string | Buffer, fileName = "export.csv") {
    let blob = null;
    if (typeof data === "string") {
      blob = new Blob([Buffer.from(data, "utf8")]);
    }
    else {
      blob = new Blob([data]);
    }
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }


  renderDeleteDialog() {
    const { selectedIDs } = this.state;
    return <Dialog
      title={i18n.s("deleteProductsConfirm")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({
          showDeleteDialog: false
        })
      }}
      onOK={async () => {
        showLoading(true);
        for (var id of selectedIDs) {
          if (await api.product.deleteProduct(id) === false) {
            showError(i18n.s('error'));
          }
        }
        this.reloadObjects();
        showLoading(false);
      }}
    >
      <div>
        <label>{i18n.s("deleteProductConfirmString", [selectedIDs.length.toString()])}</label>
      </div>
    </Dialog>
  }




  renderObjectRow(obj: IProduct): React.ReactNode {
    const { selectedIDs } = this.state;

    const isSelected = selectedIDs.findIndex(suid => suid === obj.ref) > -1;
    const search = this.state.search.trim().toUpperCase();
    if (search !== "") {
      let doNoDisplay = true;
      if (obj.ref.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.name.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.noteAuto.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (doNoDisplay) {
        if (selectedIDs.indexOf(obj.ref) !== -1) {
          this.setState({
            selectedIDs: selectedIDs.filter(suid => suid !== obj.ref)
          });
        }
        return null;
      }
    }

    return <tr key={obj.ref} >
      {
        hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)
          ?
          <Fragment>
            <td style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                disabled={obj.used && obj.used > 0 ? true : undefined}
                checked={isSelected}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    this.setState({
                      selectedIDs: [
                        ...selectedIDs,
                        obj.ref
                      ]
                    })
                  }
                  else {
                    this.setState({
                      selectedIDs: selectedIDs.filter(suid => suid !== obj.ref)
                    })
                  }
                }}
              />
            </td>
            <td><button onClick={() => { this.setState({ editProduct: obj, createNew: false }) }}><VscEdit /></button></td>
          </Fragment>
          : <Fragment><td></td><td></td></Fragment>
      }

      <td>{obj.ref}</td>
      <td>{obj.name}</td>
      <td>{obj.enabled ? i18n.s("yes") : i18n.s("no")}</td>
      <td>{obj.used}</td>
    </tr>
  }



  renderImportDialog() {
    const { importLines } = this.state;
    return <Dialog
      title={i18n.s('importProducts')}
      showOkButton={importLines !== null}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({ showImport: false, importLines: null })
      }}
      onOK={() => {
        this.importObjects();
      }}
    >
      <ImportCSV
        columns={['ref', 'name', 'noteauto', 'enabled', 'steps']}
        onImportGenerated={(result: string[][]) => { this.setState({ importLines: result }) }}
        separator="\t"
      />
    </Dialog>
  }




  render() {
    const { products, search, editProduct, showImport, showDeleteDialog, selectedIDs, createNew } = this.state;

    return (
      <Fragment>
        <div className='management'>
          <h1>{i18n.s("products")}</h1>
          <div className='search'>
            <div className='input-with-label'>
              <div><VscSearch /></div>
              <input type="text" value={search} placeholder={i18n.s('search')} onChange={(e) => { this.setState({ search: e.currentTarget.value }) }} />
            </div>
          </div>

          <div className='button-actions'>
            <button onClick={() => { this.reloadObjects() }} title={i18n.s("reload")}><VscRefresh /></button>
            {
              hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN])
                ? <Fragment>
                  <button className='success' onClick={() => { this.setState({ editProduct: null, createNew: true }) }} title={i18n.s("createProduct")}><BsPlus /></button>
                  <button onClick={() => { this.exportObjects() }} title={i18n.s("exportProducts")}><RxUpload /></button>
                  <button onClick={() => { this.setState({ showImport: true }) }} title={i18n.s("importProducts")}><RxDownload /></button>
                  <button className='danger' title={i18n.s("deleteProducts")} disabled={selectedIDs.length === 0} onClick={() => {
                    this.setState({
                      showDeleteDialog: true
                    })
                  }}><AiOutlineDelete /></button>
                </Fragment>
                : null
            }
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th style={{ width: "30px", textAlign: "center" }}>
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        if (e.currentTarget.checked) {
                          this.setState({
                            selectedIDs: products.map(p => p.ref)
                          })
                        }
                        else {
                          this.setState({
                            selectedIDs: []
                          })
                        }
                      }}
                    />
                  </th>
                  <th style={{ width: "30px", textAlign: "center" }}></th>
                  <th onClick={() => { this.sortObjectsBy("ref") }}>{i18n.s("productRef")}</th>
                  <th onClick={() => { this.sortObjectsBy("name") }}>{i18n.s("productName")}</th>
                  <th style={{ width: "100px" }} onClick={() => { this.sortObjectsBy("enabled") }}>{i18n.s("productEnabled")}</th>
                  <th style={{ width: "100px" }} onClick={() => { this.sortObjectsBy("used") }}>{i18n.s("productUsed")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  products.map((obj) => {
                    return this.renderObjectRow(obj)
                  })
                }
              </tbody>
            </table>
          </div>

          {
            editProduct !== null || createNew ? <EditProductForm
              editProductID={editProduct ? editProduct.ref : null}
              onCancel={() => {
                this.setState({ editProduct: null, createNew: false })
              }}
              onSave={(product: IProduct) => {
                this.reloadObjects();
              }}
            /> : null
          }

          {
            showImport ? this.renderImportDialog() : null
          }

          {
            showDeleteDialog ? this.renderDeleteDialog() : null
          }

        </div>
      </Fragment>

    );
  }
}

export default ProductManagement;