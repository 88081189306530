import { GetAPI, UploadAPI, PostAPI, PutAPI, DeleteAPI, DownloadAPI, IUploadFile } from './http';
import { ISubscription, ISubscriptionLine } from '../../apitypes/index';



export default class SubscriptionModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }


  async getSubscriptions(): Promise<ISubscription[]> {
    let response = await GetAPI(`${this.baseUri}/subscriptions`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  async getSubscription(id: string): Promise<ISubscription | null> {
    let response = await GetAPI(`${this.baseUri}/subscriptions/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async getCustomerSubscriptions(id: number): Promise<ISubscription[]> {
    let response = await GetAPI(`${this.baseUri}/subscriptions/customer/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }

  async getSubscriptionLines(id: string): Promise<ISubscriptionLine[]> {
    let response = await GetAPI(`${this.baseUri}/subscriptions/${id}/lines`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }

  async createSubscription(subscription: ISubscription): Promise<ISubscription | null> {
    let response = await PostAPI(`${this.baseUri}/subscriptions`, this.token, subscription, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async updateSubscription(id: string, subscription: ISubscription): Promise<ISubscription | null> {
    let response = await PutAPI(`${this.baseUri}/subscriptions/${id}`, this.token, subscription, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async validateSubscription(id: string, isValid: boolean, contractFile: File | null, mandaPortaFile: File | null, ribFile: File | null, invoiceFile: File | null): Promise<ISubscription | null> {
    const files: IUploadFile[] = [];
    if (contractFile) {
      files.push({ type: "contrat", file: contractFile });
    }
    if (mandaPortaFile) {
      files.push({ type: "mandat", file: mandaPortaFile });
    }
    if (ribFile) {
      files.push({ type: "rib", file: ribFile });
    }
    if (invoiceFile) {
      files.push({ type: "invoice", file: invoiceFile });
    }
    const response = await UploadAPI(`${this.baseUri}/subscriptions/validate/${id}/${isValid ? 1 : 0}`, this.token, files, () => { }, this.onAuthErrorCallback);
    const msg = JSON.parse(response);
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async changeSubscriptionBillState(id: string, billed: number): Promise<ISubscription | null> {
    const response = await PutAPI(`${this.baseUri}/subscriptions/bill/${id}`, this.token, { billed }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async cancelSubscription(id: string, cancel: boolean): Promise<ISubscription | null> {
    let response = await PutAPI(`${this.baseUri}/subscriptions/cancel/${id}/${cancel ? 1 : 0}`, this.token, {}, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async deleteSubscription(id: string): Promise<ISubscription | null> {
    let response = await DeleteAPI(`${this.baseUri}/subscriptions/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }


  async downloadSubscriptionFiles(id: string): Promise<Blob | null> {
    const fullDownloadURI = `${this.baseUri}/subscriptions/files/${id}`;
    const json = await DownloadAPI(fullDownloadURI, this.token, () => { }, this.onAuthErrorCallback);
    console.log(json);
    if (json && json.status === "success") {
      return json.data;
    }
    else {
      console.error(json?.error)
      return null;
    }
  }

  async downloadSubscriptionFile(id: string, fileType: string): Promise<Blob | null> {
    const fullDownloadURI = `${this.baseUri}/subscriptions/file/${id}/${fileType}`;
    const json = await DownloadAPI(fullDownloadURI, this.token, () => { }, this.onAuthErrorCallback);
    console.log(json);
    if (json && json.status === "success") {
      return json.data;
    }
    else {
      console.error(json?.error)
      return null;
    }
  }

  async addOrUpdateSubscriptionLines(subscriptionID: string, subscriptionLines: ISubscriptionLine[]): Promise<ISubscriptionLine[] | null> {
    let response = await PostAPI(`${this.baseUri}/subscriptions/${subscriptionID}/lines`, this.token, subscriptionLines, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async sendSubscriptionFiles(subscriptionID: string, recipients: string[], sendSubscription: boolean, sendPortability: boolean): Promise<boolean | null> {
    let response = await PostAPI(`${this.baseUri}/subscriptions/${subscriptionID}/mail`, this.token, { recipients, sendSubscription, sendPortability }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }

  async getSubscriptionPDF(subscriptionID: string): Promise<Uint8Array | null> {
    let response = await GetAPI(`${this.baseUri}/subscriptions/${subscriptionID}/pdf/contratOperateurFR`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return new Uint8Array(msg.data);
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async getSubscriptionMBPDF(subscriptionID: string): Promise<Uint8Array | null> {
    let response = await GetAPI(`${this.baseUri}/subscriptions/${subscriptionID}/pdf/contratOperateurMBFR`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return new Uint8Array(msg.data);
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async getPortabilityPDF(subscriptionID: string): Promise<Uint8Array | null> {
    let response = await GetAPI(`${this.baseUri}/subscriptions/${subscriptionID}/pdf/mandatPortaFR`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return new Uint8Array(msg.data);
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

}




