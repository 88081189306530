import { GetAPI, PostAPI, PutAPI, DeleteAPI } from './http';
import { IStep } from '../../apitypes/index';

export default class StepModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }


  async getSteps(): Promise<IStep[]> {
    let response = await GetAPI(`${this.baseUri}/steps`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }

  async createStep(step: string, disabled: boolean, priority: number): Promise<IStep | null> {
    let response = await PostAPI(`${this.baseUri}/steps`, this.token, { step, disabled, priority }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async updateStep(id: number, step: IStep): Promise<IStep | null> {
    let response = await PutAPI(`${this.baseUri}/steps/${id}`, this.token, step, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async deleteStep(id: number): Promise<boolean> {
    let response = await DeleteAPI(`${this.baseUri}/steps/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }


  async getProductSteps(ref: string): Promise<IStep[]> {
    let response = await GetAPI(`${this.baseUri}/steps/product/${ref}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  async setProductSteps(ref: string, steps: number[]): Promise<boolean> {
    let response = await PostAPI(`${this.baseUri}/steps/product/${ref}`, this.token, { steps }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }
}




