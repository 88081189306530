import React, { Component } from 'react';
import Dialog from '../../comps/dialog/Dialog';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import { IProduct, IStep } from '../../apitypes';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import Select from 'react-select';

import { RxPencil1 } from 'react-icons/rx';

export interface IEditProps {
  editProductID: string | null,
  onCancel: Function,
  onSave: Function
}

export interface IEditState {
  product: IProduct | null,
  products: IProduct[],
  dependencies: IProduct[],
  steps: IStep[],
  productSteps: IStep[]
}

class EditProductForm extends Component<IEditProps, IEditState> {

  constructor(props: IEditProps) {
    super(props);
    this.state = {
      products: [],
      dependencies: [],
      product: null,
      steps: [],
      productSteps: []
    }
  }


  async componentDidMount(): Promise<void> {
    const { editProductID } = this.props;
    //load dependencies and products
    showLoading(true);
    const products: IProduct[] = await api.product.getProducts();
    const product = editProductID !== null ? products.find(p => p.ref === editProductID) : {
      ref: '',
      name: '',
      noteAuto: '',
      enabled: true
    };
    const dependencies: IProduct[] | null = editProductID === null ? [] : await api.product.getProductDependencies(editProductID);
    const steps: IStep[] | null = await api.steps.getSteps();
    const productSteps: IStep[] | null = editProductID ? await api.steps.getProductSteps(editProductID) : []

    this.setState({
      product: product ? product : null,
      products: products.filter(p => p.ref !== editProductID),
      dependencies: dependencies ? dependencies : [],
      steps: steps ? steps : [],
      productSteps: productSteps ? productSteps : []
    });
    showLoading(false);
  }


  async saveObject() {
    const { onSave, editProductID } = this.props;
    const { product, dependencies, productSteps } = this.state;

    if (product === null) {
      return;
    }

    if (editProductID === null) {
      if (product.ref === '') {
        showError(i18n.s("noProductRefSet"));
        return;
      }
      if (product.name.trim() === "") {
        showError(i18n.s("noProductNameSet"));
        return;
      }

      showLoading(true);
      const createdProduct = await api.product.createProduct(product);
      if (createdProduct === null) {
        showLoading(false);
        return showError(i18n.s("productCreateError"));
      }
      //set steps
      const stepsCreated = await api.steps.setProductSteps(createdProduct.ref, productSteps.map(s => s.id));
      if (!stepsCreated) {
        showLoading(false);
        showError(i18n.s("failedToSetProductSteps"));
        return
      }
      //Save dependencies !
      const depsOK = await api.product.setProductDependencies(createdProduct.ref, dependencies.map(dep => dep.ref));
      if (!depsOK) {
        showLoading(false);
        return showError(i18n.s('error'));
      }
      showSuccess(i18n.s('success'));
      showLoading(false);
      onSave(createdProduct);
    }
    else {
      showLoading(true);
      const updatedProduct = await api.product.updateProduct(product.ref, product)
      if (!updatedProduct) {
        showLoading(false);
        return showError(i18n.s("productUpdateError"));
      }
      //set steps
      const stepsCreated = await api.steps.setProductSteps(updatedProduct.ref, productSteps.map(s => s.id));
      if (!stepsCreated) {
        showLoading(false);
        showError(i18n.s("failedToSetProductSteps"));
        return
      }
      //Save dependencies !
      const depsOK = await api.product.setProductDependencies(updatedProduct.ref, dependencies.map(dep => dep.ref));
      if (!depsOK) {
        showLoading(false);
        return showError(i18n.s('error'));
      }
      showLoading(false);
      showSuccess(i18n.s('success'));
      onSave(updatedProduct);
    }
  }


  render() {
    const { product, products, dependencies, steps, productSteps } = this.state;
    const { onCancel, editProductID } = this.props;

    if (product === null) {
      return null;
    }


    return <Dialog
      title={i18n.s("editProduct")}
      width={"70%"}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        onCancel();
      }}
      onOK={async () => {
        this.saveObject();
      }}
    >
      <div className='edit-object'>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('productRef')}</label></div>
            <input autoComplete='off' readOnly={editProductID !== null} type="text" value={product.ref} placeholder={i18n.s("productRef")} onChange={(e) => {
              this.setState(
                {
                  product: {
                    ...product,
                    ref: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('productName')}</label></div>
            <input autoComplete='off' type="text" value={product.name} placeholder={i18n.s("productName")} onChange={(e) => {
              this.setState(
                {
                  product: {
                    ...product,
                    name: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('productNoteAuto')}</label></div>
            <input autoComplete='off' type="text" value={product.noteAuto} placeholder={i18n.s("productNoteAuto")} onChange={(e) => {
              this.setState(
                {
                  product: {
                    ...product,
                    noteAuto: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('productEnabled')}</label></div>
            <select value={product.enabled ? "yes" : "no"} onChange={(e) => {
              this.setState(
                {
                  product: {
                    ...product,
                    enabled: e.currentTarget.value === "yes"
                  }
                }
              )
            }} >
              <option value="yes">{i18n.s("yes")}</option>
              <option value="no">{i18n.s("no")}</option>
            </select>
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('productDependencies')}</label></div>
            <Select
              className='react-select'
              classNamePrefix='react-select'
              isMulti
              value={
                dependencies.map((d) => {
                  return { value: d, label: d.name }
                })}
              onChange={(e) => {
                this.setState({
                  dependencies: e.map(option => option.value)
                })
              }}
              options={
                products.map((p) => {
                  return { value: p, label: p.name }
                })
              }
              menuPlacement='top'
            />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('productSteps')}</label></div>
            <Select
              className='react-select'
              classNamePrefix='react-select'
              isMulti
              value={
                productSteps.sort((s1, s2) => { return s2.priority - s1.priority }).map((s) => {
                  return { value: s, label: s.step }
                })}
              onChange={(e) => {
                this.setState({
                  productSteps: e.map(option => option.value)
                })
              }}
              options={
                steps.map((s) => {
                  return { value: s, label: s.step }
                })
              }
              menuPlacement='top'
            />
          </div>
        </div>
      </div>
    </Dialog>
  }
}

export default EditProductForm;