import { GetAPI, PostAPI, PutAPI, DeleteAPI } from './http';
import { IProduct } from '../../apitypes/index';

export default class ProductModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }


  async getProducts(): Promise<IProduct[]> {
    let response = await GetAPI(`${this.baseUri}/products`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  async createProduct(product: IProduct): Promise<IProduct | null> {
    let response = await PostAPI(`${this.baseUri}/products`, this.token, { ...product }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async updateProduct(ref: string, product: IProduct): Promise<IProduct | null> {
    let response = await PutAPI(`${this.baseUri}/products/${ref}`, this.token, product, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async deleteProduct(ref: string): Promise<boolean> {
    let response = await DeleteAPI(`${this.baseUri}/products/${ref}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }



  async getProductDependencies(ref: string): Promise<IProduct[] | null> {
    let response = await GetAPI(`${this.baseUri}/products/dependencies/${ref}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }

  async setProductDependencies(ref: string, dependencies: string[]): Promise<boolean> {
    let response = await PostAPI(`${this.baseUri}/products/dependencies/${ref}`, this.token, { dependencies }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }

}




