import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { IStep } from '../../apitypes/index';
import Dialog from '../../comps/dialog/Dialog';

import { BsPlus } from 'react-icons/bs';
import { VscSearch, VscRefresh, VscEdit } from 'react-icons/vsc';
import { AiOutlineDelete } from 'react-icons/ai';
import { RxPencil1 } from 'react-icons/rx';

interface IState {
  search: string,
  steps: IStep[],
  editStep: IStep | null,
  showDeleteDialog: boolean,
  selectedIDs: number[],
  sortBy: string,
  sortOrder: E_SORTORDER,
}

class StepsManagement extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      search: '',
      steps: [],
      editStep: null,
      showDeleteDialog: false,
      selectedIDs: [],
      sortBy: '',
      sortOrder: E_SORTORDER.ASC,
    }
  }

  async componentDidMount(): Promise<void> {
    this.reloadObjects();
  }

  sortObjectsBy(sortBy: string) {
    const { steps, sortOrder } = this.state;
    let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
    if (sortBy === this.state.sortBy) {
      //Change sort order
      if (sortOrder === E_SORTORDER.ASC) {
        newSortOrder = E_SORTORDER.DESC;
      }
    }
    console.log(`Sort ${sortBy} ${newSortOrder}`);

    this.setState({
      sortOrder: newSortOrder,
      sortBy: sortBy,
      steps: sortObjects(steps, sortBy, newSortOrder)
    });
  }


  async reloadObjects() {
    showLoading(true);
    this.setState({
      steps: await api.steps.getSteps(),
      editStep: null,
      selectedIDs: [],
      showDeleteDialog: false,
    });
    showLoading(false);
  }

  async createNewObject() {
    var editStep: IStep = {
      id: -1,
      step: '',
      disabled: false,
      priority: 1
    }
    this.setState({
      editStep
    });
  }


  renderDeleteDialog() {
    const { selectedIDs } = this.state;
    return <Dialog
      title={i18n.s("deleteStepsConfirm")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({
          showDeleteDialog: false
        })
      }}
      onOK={async () => {
        showLoading(true);
        let failed = 0;
        for (var id of selectedIDs) {
          const ok = await api.steps.deleteStep(id);
          if (!ok) {
            failed++;
          }
        }
        this.reloadObjects();
        if (failed) {
          showError(i18n.s("deleteStepsFailure"));
        }
        else {
          showSuccess(i18n.s('success'));
        }
        showLoading(false);
      }}
    >
      <div>
        <label>{i18n.s("deleteStepsConfirmString", [selectedIDs.length.toString()])}</label>
      </div>
    </Dialog>
  }


  renderObjectMod(): React.ReactNode {
    const { editStep } = this.state;
    if (editStep === null) {
      return null;
    }

    return <Dialog
      title={i18n.s("editStep")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({
          editStep: null,
        });
      }}
      onOK={async () => {
        let createdUpdatedStep: IStep | null = null;
        if (editStep.id === -1) {
          createdUpdatedStep = await api.steps.createStep(editStep.step, editStep.disabled, editStep.priority);
        }
        else {
          createdUpdatedStep = await api.steps.updateStep(editStep.id, editStep);
        }

        if (createdUpdatedStep) {
          this.setState({
            editStep: null,
          });
          this.reloadObjects();
        }
        else {
          showError(i18n.s("failedToCreateUpdateStep"))
        }
      }}
    >
      <div className='edit-object'>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('stepDescription')}</label></div>
            <input autoComplete='off' type="text" value={editStep.step} placeholder={i18n.s("stepDescription")} onChange={(e) => {
              this.setState(
                {
                  editStep: {
                    ...editStep,
                    step: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('stepPriority')}</label></div>
            <div><small>{i18n.s('stepPriorityDesc')}</small></div>
            <select value={editStep.priority} onChange={(e) => {
              this.setState(
                {
                  editStep: {
                    ...editStep,
                    priority: parseInt(e.currentTarget.value)
                  }
                }
              )
            }}>
              {
                Array.from(Array(250).keys()).map((a, index) => {
                  return <option key={index + 1} value={index + 1}>{index + 1}</option>
                })
              }
            </select>
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('stepDisabledState')}</label></div>
            <select value={editStep.disabled ? "disabled" : "enabled"} onChange={(e) => {
              this.setState({
                editStep: {
                  ...editStep,
                  disabled: e.currentTarget.value === "disabled"
                }
              })
            }}>
              <option value="enabled">{i18n.s("stepEnabled")}</option>
              <option value="disabled">{i18n.s("stepDisabled")}</option>
            </select>
          </div>
        </div>
      </div>
    </Dialog>
  }

  renderObjectRow(obj: IStep): React.ReactNode {
    const { selectedIDs } = this.state;

    const isSelected = selectedIDs.findIndex(suid => suid === obj.id) > -1;
    const search = this.state.search.trim().toUpperCase();
    if (search !== "") {
      let doNoDisplay = true;
      if (obj.step.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (doNoDisplay) {
        if (obj.id !== null && selectedIDs.indexOf(obj.id) !== -1) {
          this.setState({
            selectedIDs: selectedIDs.filter(suid => suid !== obj.id)
          });
        }
        return null;
      }
    }

    return <tr key={obj.id} >
      <td style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          disabled={obj.disabled}
          checked={isSelected}
          onChange={(e) => {
            if (obj.id === null)
              return;
            if (e.currentTarget.checked) {
              this.setState({
                selectedIDs: [
                  ...selectedIDs,
                  obj.id
                ]
              })
            }
            else {
              this.setState({
                selectedIDs: selectedIDs.filter(suid => suid !== obj.id)
              })
            }
          }}
        />
      </td>
      <td><button onClick={async () => {
        if (obj.id) {
          this.setState({
            editStep: obj
          })
        }
      }}><VscEdit /></button></td>
      <td >{obj.step}</td>
      <td >{obj.priority}</td>
      <td >{obj.disabled ? i18n.s("stepDisabled") : i18n.s("stepEnabled")}</td>
    </tr>
  }





  render() {
    const { steps, search, editStep, selectedIDs, showDeleteDialog } = this.state;

    return (
      <Fragment>
        <div className='management'>
          <h1>{i18n.s("steps")}</h1>
          <div className='search'>
            <div className='input-with-label'>
              <div><VscSearch /></div>
              <input type="text" value={search} placeholder={i18n.s('search')} onChange={(e) => { this.setState({ search: e.currentTarget.value }) }} />
            </div>
          </div>

          <div className='button-actions'>
            <div className='inline'>
              <button onClick={() => { this.reloadObjects() }} title={i18n.s('reload')}><VscRefresh /></button>
              <button className='success' onClick={() => { this.createNewObject() }} title={i18n.s("createStep")}><BsPlus /></button>
              <button className='danger' title={i18n.s("deleteSteps")} disabled={selectedIDs.length === 0} onClick={() => {
                this.setState({
                  showDeleteDialog: true
                })
              }}><AiOutlineDelete /></button>
            </div>

          </div>

          <table>
            <thead>
              <tr>
                <th style={{ width: "30px", textAlign: "center" }}>
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      if (e.currentTarget.checked) {
                        this.setState({
                          selectedIDs: steps.filter(s => s.id).map(s => s.id)
                        })
                      }
                      else {
                        this.setState({
                          selectedIDs: []
                        })
                      }
                    }}
                  />
                </th>
                <th style={{ width: "30px", textAlign: "center" }}></th>
                <th onClick={() => { this.sortObjectsBy("step") }}>{i18n.s("stepDescription")}</th>
                <th onClick={() => { this.sortObjectsBy("priority") }}>{i18n.s("stepPriority")}</th>
                <th onClick={() => { this.sortObjectsBy("disabled") }}>{i18n.s("stepState")}</th>
              </tr>
            </thead>
            <tbody>
              {
                steps.map((obj) => {
                  return this.renderObjectRow(obj)
                })
              }
            </tbody>
          </table>

          {
            editStep !== null ? this.renderObjectMod() : null
          }

          {
            showDeleteDialog ? this.renderDeleteDialog() : null
          }


        </div>
      </Fragment>

    );
  }
}

export default StepsManagement;